<template>
  <div>
    <a
      id="technical-support-floating-btn"
      class="btn"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=50322346011&text=Necesito%20Asistencia%20T%C3%A9cnica%20"
    >
      <svg
        width="35"
        height="35"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.16909 29.163C5.24252 25.8105 4.56781 21.8473 5.27168 18.0177C5.97554 14.188 8.00955 10.7553 10.9918 8.3641C13.974 5.97294 17.6993 4.78781 21.4681 5.03125C25.2369 5.2747 28.7899 6.92998 31.4601 9.68627C34.1303 12.4426 35.7339 16.1102 35.9697 20.0006C36.2056 23.891 35.0575 27.7365 32.7411 30.8149C30.4246 33.8933 27.0992 35.993 23.3892 36.7195C19.6792 37.4461 15.8399 36.7497 12.5921 34.761L12.5922 34.7608L7.23652 36.3404C7.01494 36.4057 6.78046 36.4087 6.55738 36.349C6.3343 36.2894 6.13074 36.1692 5.96779 36.001C5.80484 35.8328 5.68843 35.6227 5.63062 35.3924C5.57282 35.1621 5.57572 34.9201 5.63903 34.6913L7.16921 29.1629L7.16909 29.163Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.3523 30C22.5981 30.0025 20.8607 29.6588 19.2396 28.9887C17.6185 28.3186 16.1456 27.3351 14.9052 26.0948C13.6649 24.8544 12.6814 23.3815 12.0113 21.7604C11.3412 20.1393 10.9975 18.4019 11 16.6477C11.004 15.413 11.4975 14.2303 12.3724 13.359C13.2472 12.4877 14.4319 11.9989 15.6667 12C15.8692 12 16.0681 12.0535 16.2433 12.1552C16.4184 12.2568 16.5636 12.403 16.6641 12.5788L18.6126 15.9887C18.7301 16.1944 18.7907 16.4276 18.7882 16.6644C18.7857 16.9012 18.7201 17.1331 18.5983 17.3362L17.0334 19.9443C17.8385 21.7309 19.2691 23.1615 21.0557 23.9666L23.6638 22.4018C23.8669 22.2799 24.0988 22.2143 24.3356 22.2118C24.5724 22.2093 24.8056 22.2699 25.0113 22.3874L28.4212 24.3359C28.597 24.4364 28.7432 24.5816 28.8448 24.7567C28.9465 24.9319 29 25.1308 29 25.3333C28.9965 26.5666 28.5063 27.7487 27.636 28.6226C26.7656 29.4964 25.5856 29.9914 24.3523 30Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
    <b-tooltip target="technical-support-floating-btn" triggers="hover">
      ¿Necesitas ayuda? Contacta con <b>soporte técnico</b>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "TechnicalSupportFloatingBtn",
};
</script>

<style scoped>
#technical-support-floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #00e676;
  box-shadow: 4px 4px 2px rgb(0 230 118 / 8%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
