<template>
  <div class="d-flex flex-column flex-root" v-if="true">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div>
      <v-container fluid>
        <v-row>
          <v-col style="padding:0 !important">
            <div class="d-flex flex-column">
              <div class="header-top test-color ">
                <div class="container ">
                  <div class=" d-flex">
                    <!--begin::Logo-->
                    <div class="mr-20 mx-auto">
                      <router-link to="/main_form">
                        <img
                          alt="Logo"
                          :src="layoutConfig('self.logo.default')"
                          class="max-h-35px"
                        />
                      </router-link>
                    </div>
                    <!--end::Logo-->
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <!-- begin:: Content Head -->
        <KTSubheader
          v-if="subheaderDisplay && displaySubheaderOnDashboard"
          v-bind:breadcrumbs="breadcrumbs"
          v-bind:title="pageTitle"
        />
        <!-- end:: Content Head -->

        <!-- begin:: Content Body -->
        <div class="d-flex flex-column-fluid">
          <div class="container-fluid">
            <div class="d-lg-flex flex-row-fluid">
              <!-- begin:: Aside Left -->
              <KTAside v-if="asideEnabled"></KTAside>
              <!-- end:: Aside Left -->
              <div class="content-wrapper flex-row-fluid">
                <v-app>
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </v-app>
              </div>
            </div>
          </div>
        </div>
      </div>

      <KTFooter></KTFooter>
      <TechnicalSupportFloatingBtn></TechnicalSupportFloatingBtn>
    </div>

    <!-- <KTScrollTop></KTScrollTop> -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import KTSubheader from "@/view/layout/subheader/Subheader.vue";
  import KTFooter from "@/view/layout/footer/Footer.vue";
  import HtmlClass from "@/core/services/htmlclass.service";
  import KTScrollTop from "@/view/layout/extras/ScrollTop";
  import TechnicalSupportFloatingBtn from "@/view/components/TechnicalSupportFloatingBtn";
  import Loader from "@/view/content/Loader.vue";
  import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
  } from "@/core/services/store/htmlclass.module.js";
  import {
    VERIFY_TOKEN,
    VERIFY_AUTH,
  } from "@/core/services/store/auth.module.js";
  import JwtService from "@/core/services/jwt.service";

  export default {
    name: "Layout",
    components: {
      KTSubheader,
      KTFooter,
      KTScrollTop,
      TechnicalSupportFloatingBtn,
      Loader,
    },
    beforeMount() {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // initialize html element classes
      HtmlClass.init(this.layoutConfig());

      this.verifyToken();
    },
    mounted() {
      // check if current user is authenticated
      // if (!this.isAuthenticated) {
      //   this.$router.push({ name: "login" });
      // }

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    },
    methods: {
      footerLayout(type) {
        return this.layoutConfig("footer.layout") === type;
      },
      verifyToken() {
        const token = this.$route.params.token;
        const reg = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
        if (reg.test(token)) {
          this.$store
            .dispatch(VERIFY_TOKEN, token)
            .then(() => {
              this.$router.push({ name: "main_form" });
            })
            .catch(() => {
              window.location = "https://portal.ricaldone.edu.sv";
            });
        } else if (JwtService.getToken()) {
          this.$store.dispatch(VERIFY_AUTH);
        } else {
          if (!this.isAuthenticated) {
            this.$router.push({ name: "unauthenticated" });
          }
        }
      },
    },
    computed: {
      ...mapGetters([
        "breadcrumbs",
        "pageTitle",
        "layoutConfig",
        "getClasses",
        "currentUserPersonalInfo",
      ]),

      /**
       * Check if the page loader is enabled
       * @returns {boolean}
       */
      loaderEnabled() {
        return !/false/.test(this.layoutConfig("loader.type"));
      },

      /**
       * Check if container width is fluid
       * @returns {boolean}
       */
      contentFluid() {
        return this.layoutConfig("content.width") === "fluid";
      },

      /**
       * Page loader logo image using require() function
       * @returns {string}
       */
      loaderLogo() {
        return process.env.BASE_URL + this.layoutConfig("loader.logo");
      },

      /**
       * Check if the left aside menu is enabled
       * @returns {boolean}
       */
      asideEnabled() {
        return !!this.layoutConfig("aside.self.display");
      },

      /**
       * Set the right toolbar display
       * @returns {boolean}
       */
      toolbarDisplay() {
        // return !!this.layoutConfig("toolbar.display");
        return false;
      },

      /**
       * Set the subheader display
       * @returns {boolean}
       */
      subheaderDisplay() {
        return !!this.layoutConfig("subheader.display");
      },

      /**
       * Set the subheader display on dashboard page
       * @returns {boolean}
       */
      displaySubheaderOnDashboard() {
        return this.$route.name !== "file";
      },
    },
  };
</script>

<style lang="scss">
  @import "~vuetify/dist/vuetify.css";

  .theme--light.v-application {
    background: transparent !important;
  }
  .v-application code {
    box-shadow: none !important;
  }

  // Table
  table thead tr th {
    font-size: 1rem !important;
  }

  table.v-data-table {
    max-width: 100% !important;
  }

  table.v-table tbody td {
    font-size: 1rem !important;
  }

  .v-data-table__wrapper .text-start {
    white-space: nowrap !important;
  }

  .v-data-footer {
    font-size: 0.95rem !important;
  }

  // Card
  .card-label {
    font-size: 1.4rem !important;
  }

  .card-label span {
    font-size: 1.05rem;
  }

  .test-color {
    background-color: #263238 !important;
  }

  .test-color-second {
    background-color: #263238 !important;
  }

  @media (max-width: 990px) {
    .test-color-second {
      background-color: #ffb300 !important;
      border-radius: 0px 0px 5px 5px;
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hidden-scroll::-webkit-scrollbar {
    display: none !important;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hidden-scroll {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
</style>
